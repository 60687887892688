// 
// cta.scss
// 

.cta-bg {
    background-image: url("../images/counter-bg.jpg");
    background-size: cover;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
}