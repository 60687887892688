// 
// Helper
// 


.section {
    padding-top: 90px !important;
    padding-bottom: 90px !important;
    position: relative;
}

.section-sm {
    padding-top: 70px !important;
    padding-bottom: 70px !important;
    position: relative;
}

.custom-pt {
    margin-top: 16rem;
}

.bg-overlay {
    position: absolute;
    background-color: $bg-overlay;
    opacity: 0.85;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
}


// title
.subtitle {
    color: $dark;
    text-transform: uppercase;  
    font-weight: normal;
}
.title {
    font-weight: $font-weight-bold;
    font-size: 30px;
    margin-top: 18px;
    line-height: 1.3;
}

.fw-semibold {
    font-weight: $font-weight-semibold;
}


.fs-12{
    font-size: 12px;
}
.fs-13{
    font-size: 13px;
}
.fs-14{
    font-size: 14px;
}
.fs-16{
    font-size: 16px;
}
.fs-17{
    font-size: 17px;
}
.fs-18{
    font-size: 18px;
}
.fs-19{
    font-size: 19px;
}
.fs-20{
    font-size: 20px;
}
.fs-21{
    font-size: 21px;
}
.fs-22{
    font-size: 22px;
}
.fs-23{
    font-size: 23px;
}
.fs-24{
    font-size: 24px;
}
.fs-26{
    font-size: 26px;
}
.fs-38{
    font-size: 38px;
}

.text-light-custom {
    color: darken($gray-400, 15%);
}

.modal-backdrop {
    background-color: $black;
}

.shadow-lg {
    box-shadow: $box-shadow-lg !important;
}

.rounded-lg {
    border-radius: 8px;
}

// right-arrow
.right-arrow {
    position: relative;
    top: 2px;
    line-height: 0;
}



/**BACK TO TOP**/

.back-to-top-btn {
    position: fixed;
    padding: 0px 5px !important;
    bottom: 10px;
    right: 20px;
    display: none;
    text-align: center;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    i {
        font-size: 22px;
        line-height: 30px;
    }
}



#mkLightboxContainer {
    z-index: 999;
}